<template>
  <section class="w-full h-screen px-10 md:px-36 mt-32 md:mt-0" id="donate">
    <div class="w-full h-full flex flex-col md:flex-row justify-between items-center">
      <div class="w-full md:w-1/2">
        <img src="/images/6660.jpg" alt="" />
      </div>

      <div class="w-full md:w-2/5">
        <h4 class="font-bold text-2xl">Donate</h4>

        <br />
        <p class="text-justify text-sm text-gray-500">
          <span class="font-medium">Alone we can do so little. Together we can do so much</span>. Help us
          continue helping others by donating to our work. Leave your name and
          email or phone and we will be in touch as soon as possible.
        </p>

        <br />
        <div>
          <form @submit.prevent="">
            <input
              type="text"
              name=""
              id=""
              class="
                w-full
                border
                p-1
                outline-none
                focus:shadow-md
                transition-shadow
              "
              placeholder="Full Name / Organisation Name"
              required
            />

            <input
              type="text"
              name=""
              id=""
              class="
                w-full
                border
                p-1
                mt-4
                outline-none
                focus:shadow-md
                transition-shadow
              "
              placeholder="Email / Phone"
              required
            />

            <button
              type="submit"
              class="
                mt-4
                bg-yellow-400
                w-full
                py-1
                font-medium
                hover:bg-yellow-500
                transition-colors
              "
            >
              Send
            </button>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style>
</style>