const intersectionObserver = new IntersectionObserver(
  (entries) => {
    entries.forEach((entry) => {
     if (entry.isIntersecting) {
         const navItems = document.getElementsByClassName(`${entry.target.id}-nav-item`)

         navItems.forEach(navItem => {
          navItem.classList.add('active')
         })

     } else {
        const navItems = document.getElementsByClassName(`${entry.target.id}-nav-item`)

         navItems.forEach(navItem => {
          navItem.classList.remove('active')
         })
     }
    });
  },
  {
    threshold: .8,
  }
);

export default {
  created: (el) => {
    intersectionObserver.observe(el);
  },
};
