import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/tailwind.css";
import navTrigger from "./directives/navTrigger";
import navItemTrigger from "./directives/navItemTrigger";

createApp(App)
  .use(store)
  .use(router)
  .directive("navTrigger", navTrigger)
  .directive("navItemTrigger", navItemTrigger)
  .mount("#app");
