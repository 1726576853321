<template>
  <section
    class="w-full md:h-screen flex flex-col md:flex-row md:justify-between items-center"
    v-navItemTrigger
    id="about"
  >
    <div class="w-full md:w-1/2 bg-gray-50 pl-10 md:pl-36 pr-10 py-24 text-left">
      <h2 class="font-bold text-4xl flex items-baseline">
        <span class="font-normal">About</span> <span class="mx-3">Us</span>
        <div class="w-24 h-1 bg-yellow-500"></div>
      </h2>

      <br />
      <p class="text-justify">
        Active Citizens for Change and for Sustainable Development (ACCSuD) is a
        charitable Non-Governmental Organization (NGO), registered under the
        Trustees Incorporation acts of 2013. ACCSUD is registered NGO number:
        TR: INC: 7642. ACCSuD dedicated to the advancement of equality,
        promotion of social justice and the respect of human dignity in Malawi.
        Our role is contributing to the creation of a human rights-based culture
        in Malawi through advocacy and direct empowerment of women, girls, young
        people, children and their communities.
      </p>

      <br />
      <p class="text-justify">
        ACCSuD builds their capabilities so that they are able to rise up to the
        consciousness and capacity of advocating for the promotion of their
        human rights, protect themselves and be protected from human rights
        violations of all forms and be able to actively participate in the
        governance, policy formulation and decision-making processes including
        democratization in Malawi.
      </p>
    </div>

    <div class="w-full md:w-1/2">
      <div class="slider">
        <div class="slides">
          <!-- slide 1 -->
          <div class="" id="slide-1">
            <i class="fa fa-low-vision" aria-hidden="true"></i>
            <h4 class="font-medium text-xl">Vision</h4>

            <br />
            <p class="text-center text-sm">
              A future society with highly self-motivated citizens responsive to
              ever-changing human rights, socio-economic and political pressure
              for the betterment of their welfare
            </p>
          </div>

          <!-- slide 2 -->
          <div class="" id="slide-2">
            <i class="fa fa-rocket" aria-hidden="true"></i>
            <h4 class="font-medium text-xl">Mission</h4>

            <br />
            <p class="text-center text-sm">
              To sensitize, motivate, mobilize, and empower communities and
              other stakeholders towards owning development initiatives through
              effective involvement and participation
            </p>
          </div>

          <!-- slide 3 -->
          <div class="" id="slide-3">
            <i class="fa fa-bullseye" aria-hidden="true"></i>
            <h4 class="font-medium text-xl">Goal</h4>

            <br />
            <p class="text-center text-sm">
              To contribute towards the attainment of highly motivated and
              empowered communities through active participation in
              sociol-economic and political development of the country
            </p>
          </div>

          <!-- slide 4 -->
          <div class="" id="slide-4">
            <i class="fa fa-star" aria-hidden="true"></i>
            <h4 class="font-medium text-xl">Core Values</h4>

            <br />
            <p class="text-center text-sm">
              Inspiring leadership, Sustainability mindset, Transparency &amp;
              accountability, Efficiency &amp; effectiveness, Financial prudence,
              Commitment &amp; dedication, Result &amp; action-oriented, Enhancing
              stakeholder engagement, Courage and Resilience, <br />Gender and
              cultural sensitivity
            </p>
          </div>
        </div>

        <br class="hidden md:visible" />
        <div
          class="px-10 md:px-36 flex justify-around w-2/3 m-auto"
          id="slider-controlls"
        >
          <a href="#slide-1"
            ><div class="w-4 h-4 rounded-full bg-yellow-200"></div
          ></a>
          <a href="#slide-2"
            ><div class="w-4 h-4 rounded-full bg-yellow-200"></div
          ></a>
          <a href="#slide-3"
            ><div class="w-4 h-4 rounded-full bg-yellow-200"></div
          ></a>
          <a href="#slide-4"
            ><div class="w-4 h-4 rounded-full bg-yellow-200"></div
          ></a>
        </div>
      </div>

      <!-- <hr class="my-10" />

      <div class="w-full">
        <h4 class="font-medium text-xl">Core Values</h4>
        <h6 class="w-full flex flex-col items-center text-sm">
          <span class="flex items-center">
            Inspiring leadership
            <div class="dot"></div>
            Sustainability mindset
            <div class="dot"></div>
            Transparency &amp; accountability
          </span>

          <span class="flex items-center">
            Efficiency &amp; effectiveness
            <div class="dot"></div>
            Financial prudence
            <div class="dot"></div>
            Commitment &amp; dedication
          </span>

          <span class="flex items-center">
            Result &amp; action-oriented
            <div class="dot"></div>
            Enhancing stakeholder engagement
            <div class="dot"></div>
            Courage and Resilience
          </span>

          <span> Gender and cultural sensitivity </span>
        </h6>
      </div> -->
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style scoped>
.slider {
  width: 600px;
  /* text-align: center; */
  margin: auto;
  overflow: hidden;
}

.slides {
  display: flex;
  overflow-x: hidden;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
}

.slides > div {
  scroll-snap-align: start;
  flex-shrink: 0;
  width: 600px;
  margin-right: 50px;
  transform-origin: center center;
  transform: scale(1);
  transition: transform 0.5s;
  position: relative;
}

#slider-controlls a:active div {
  background-color: rgb(255, 145, 0);
}

.dot {
  width: 0.6em;
  height: 0.6em;
  border-radius: 50%;
  background-color: gray;
  margin: 0.5em;
}

@media (max-width: 425px) {
  .slider {
    width: 100%;
  }

  .slides > div {
    width: 100%;
    padding: 0 .4em;
    margin: 3em auto;
  }
}
</style>