<template>
  <section
    class="w-full md:h-screen flex flex-col md:flex-row justify-between"
    v-navItemTrigger
    id="programs"
  >
    <div class="w-full md:w-1/2 pl-10 md:pl-36 pr-10 py-24">
      <h2 class="font-bold text-4xl flex items-baseline">
        <span class="font-normal">Programs</span> <span class="mx-2"></span>
        <div class="w-24 h-1 bg-yellow-500"></div>
      </h2>

      <br />
      <p class="text-justify">
        To achieving sustainable human rights development and real social change
        depends on working with and adequately equipping young people, women and
        girls to participate in all areas of the Malawian society. Since 2015,
        we pride ourself in rights-based and inclusive programming to facilitate
        meaningful constituents’ participation and development, advance gender
        equality, protect and safeguard the rights of children, women and girls,
        and promote good governance and social accountability at all levels.
      </p>

      <br>
      <h4 class="font-medium text-xl text-left">Our Thematic Areas</h4>
      <p class="text-left mt-2">Human Rights, Health and Livelihood</p>
    </div>

    <div class="w-full md:w-1/2 bg-gry-50 px-5 md:px-0 md:py-24">
      <div class="flex h-96 md:h-full justify-center items-center mx-auto">
        <div class="">
          <i
            class="
              fas
              fa-chevron-left
              text-gray-500
              hover:text-gray-800
              transition-colors
              cursor-pointer
            "
            @click="slideRight"
          ></i>
        </div>
        <div
          class="h-full border rounded-md mx-4 shadow-md"
          id="slides-container"
        >
          <div class="absolute flex w-full h-full justify-center items-center">
            <p>Nothing more to show</p>
          </div>
          <div class="slides bg-white" id="slides">
            <div class="slide" v-for="(slide, i) in slides" :key="i">
              <div class="w-full h-1/2">
                <img
                  :src="'/images/' + slide.img"
                  alt=""
                  class="w-full h-full object-cover"
                />
              </div>

              <div class="w-full px-2 py-2">
                <h4 class="font-semibold md:text-xl capitalize mt-">
                  {{ slide.title }}
                </h4>

                <p class="text-justify text-sm mt-2">
                  {{ slide.body }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="">
          <i
            class="
              fas
              fa-chevron-right
              text-gray-500
              hover:text-gray-800
              transition-colors
              cursor-pointer
            "
            @click="slideLeft"
          ></i>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { onMounted } from "vue";

export default {
  setup() {
    let position = 0;

    let slidesRef;

    const slides = [
      {
        img: "hands.jpg",
        title: "promote social accountability",
        body: "ACCSUD stimulates citizens’ voice and provides local communities with platforms to demand and promote social accountability. We use Complaints and Feedback Mechanisms (CFMs), community interface and dialogue sessions, budget tracking, and the Radio programmes to facilitate and promote real social accountability.",
      },
      {
        img: "parallax1.jpg",
        title: "promote access to information",
        body: "We run a 24/7 community-of-interest online key point forum, which provides a trusted platform for engagement and disseminating information on issues such as governance and human rights, GBV, SRHR, gender equality, education, and sustainable development. ACCSUD also uses SMS bulk tool, social media, web-based portals, and community participatory theatre.",
      },
      {
        img: "child.jpg",
        title: "empower the youth",
        body: "ACCSUD provides opportunities and competencies to young people aged 10 to 29 for them to adequately respond to issues affecting them especially poverty. We develop young people by empowering them with life skills, technical skills, resources, information, digital and financial literacy to enhance their independence and active participation in development processes.",
      },
      {
        img: "smilingboy.jpg",
        title: "mental health",
        body: "ACCSuD enhance mental health literacy and provide youth friendly services that account for the complex social and environmental factors contributing to young people’s mental health, resilience and wellbeing. ACCSuD working with young people with lived experiences, building upon local systems, and engaging with local government and policy makers where necessary.",
      },
      {
        img: "parallax2.jpg",
        title: "achieve gender equality",
        body: "ACCSUD employs Gender Transformative Approaches, involves males as agents of change, and facilitates community dialogue with community gatekeepers including traditional and religious leaders to transform social norms and promote gender equality. We empower women and girls with skills, resources, information and financial literacy to enhance their bargaining power and assertiveness to participate equally in all…",
      },
      {
        img: "stop.jpg",
        title: "end violence against women/girls",
        body: "ACCSUD prevents and responds to violence against women/girls using the Gender-Based Violence (GBV) Crisis Line which includes a 24/7 toll-free hotline (1212) for reporting VAWG, and accessing psychosocial support and information.",
      },
      {
        img: "kidsbythewindow.jpg",
        title: "protect children",
        body: "ACCSUD safeguards and protects children from violence and abuse using the Save Child Helpline which includes a 24/7 toll-free hotline (1313) for reporting child violence and abuse, and accessing online counseling, psychosocial support and information.",
      },
      {
        img: "parallax1.jpg",
        title: "safe care abortion",
        body: "ACCSuD promote and advocate for safe care abortion as a women human rights. This includes the decision to carry a pregnancy to term or seek an abortion. No woman should be obligated to continue an unwanted pregnancy. Women’s human rights should be respected, protected and fulfilled. ACCSuD believe that access to safe abortion is a matter of equality and should be an integral part of women health services, and should be provided as early as possible and as late as necessary.",
      },
    ];

    onMounted(() => {
      slidesRef = document.getElementById("slides");
    });

    // slide to left
    const slideLeft = () => {
      let offset = document.body.clientWidth <= 425 ? 290 : 450;
      position = position - offset;
      slidesRef.style.transform = `translateX(${position}px)`;
    };

    // slide to right
    const slideRight = () => {
      let offset = document.body.clientWidth <= 425 ? 290 : 450;
      position = position + offset;
      slidesRef.style.transform = `translateX(${position}px)`;
    };

    return {
      slides,
      slideLeft,
      slideRight,
    };
  },
};
</script>

<style scoped>
#slides-container {
  position: relative;
  width: 450px;
  overflow: hidden;
}

.slides {
  position: absolute;
  height: inherit;
  display: flex;
  flex-direction: row;
  justify-content: start;
  /* align-items: center; */
  transition: transform 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  /* transform: translateX(-450px); */
}

.slide {
  flex-shrink: 0;
  width: 450px;
  height: inherit;
  overflow-x: hidden;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  position: relative;
  display: flex;
  flex-direction: column;
  /* justify-content: center;
  align-items: center; */
  /* padding: 0 1.3em; */
  text-align: left;
}

.slide p {
  font-size: 0.88em;
}

@media (max-width: 425px) {
  #slides-container {
    width: 290px;
    height: 500px;
  }

  .slide {
    width: 290px;
    height: inherit;
  }
}
</style>