<template>
  <div>
    <!-- Navigation -->
    <Nav />

    <!-- Landing page -->
    <LandingPage />

    <!-- About page -->
    <About />

    <!-- Prorams Page -->
    <Programs />

    <!-- Donate Page -->
    <Donate />

    <!-- Footer Page -->
    <Footer />
  </div>
</template>

<script>
import LandingPage from "../components/LangingPage.vue";
import Nav from "../components/Nav.vue";
import About from '../components/About.vue';
import Programs from '../components/Programs.vue';
import Footer from '../components/Footer.vue';
import Donate from '../components/Donate.vue';

export default {
  components: { Nav, LandingPage, About, Programs, Footer, Donate },
  setup() {},
};
</script>
