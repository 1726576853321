<template>
  <section
    class="
      w-full
      h-screen
      flex flex-col
      md:flex-row
      justify-center
      md:justify-between
      items-center
    "
    v-navTrigger
    v-navItemTrigger
    id="home"
  >
    <div class="w-full md:w-1/2 visible md:hidden">
      <img src="/images/4492716.jpg" alt="" />
    </div>

    <div class="w-full md:w-1/2 text-left pl-10 md:pl-36">
      <h2 class="capitalize font-bold text-6xl md:text-8xl">advocate</h2>
      <p class="capitalize mt-4 md:mt-0">
        for equal treatment and the enjoyment <br />of <b>human rights</b> in
        Malawi.
      </p>

      <br /><br />
      <a
        href="#about"
        class="
          bg-yellow-400
          px-4
          py-3
          rounded-full
          font-medium
          text-gray-700
          hover:shadow-lg hover:bg-yellow-500
          transition-all
        "
        >About Us</a
      >
    </div>

    <div class="w-1/2 hidden md:block">
      <img src="/images/4492716.jpg" alt="" />
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style>
</style>