const intersectionObserver = new IntersectionObserver(
  (entries) => {
    const nav = document.getElementById("nav");

    entries.forEach((entry) => {
      if (!entry.isIntersecting) {
        nav.classList.add("shadow-md");
      } else {
        nav.classList.remove("shadow-md");
      }
    });
  },
  {
    threshold: 1,
  }
);

export default {
  created: (el) => {
    intersectionObserver.observe(el);
  },
};
