<template>
  <div
    class="
      fixed
      w-full
      flex
      justify-between
      items-center
      px-10
      md:px-36
      py-3
      z-40
      bg-white
      transition-shadow
    "
    id="nav"
  >
    <!-- logo -->
    <div class="flex justify-start items-center">
      <img src="@/assets/logoR.jpg" alt="" width="40" />
      <span class="text-lg font-bold ml-2">ACCSuD</span>
    </div>

    <!-- Navigation items -->
    <ul class="md:flex justify-between w-2/5 hidden md:visible">
      <li
        v-for="(navItem, i) in navItems"
        :key="i"
        class="capitalize hover:text-yellow-500 transition-all"
      >
        <a
          :href="navItem.href"
          :class="`transition-all ${navItem.name}-nav-item`"
          >{{ navItem.name }}</a
        >
      </li>
      <!-- Special Button -->
      <li>
        <a
          href="/#donate"
          class="
            bg-yellow-400
            hover:bg-yellow-500
            transition-shadow
            px-3
            py-2
            rounded-md
            shadow-md
            font-medium
          "
          >Donate</a
        >
      </li>
    </ul>

    <!-- Mobile menu button -->
    <div class="md:hidden">
      <button @click="toggleDrawer">
        <i class="fa fa-bars text-gray-500" aria-hidden="true"></i>
      </button>
    </div>
  </div>

  <div
    :class="`
      fixed
      w-9/12
      h-screen
      bg-white
      shadow-md
      z-50
      px-10
      py-10
      md:hidden
      transform
      ${drawerPos}
      transition-transform
    `"
  >
    <ul class="text-left">
      <li class="my-3" v-for="(navItem, i) in navItems" :key="i">
        <a :href="navItem.href" class="capitalize">
          {{ navItem.name }}
        </a>
      </li>

      <!-- You just know -->
      <li class="mt-7">
        <a
          href="/#donate"
          class="
            bg-yellow-400
            hover:bg-yellow-500
            px-3
            py-2
            font-medium
          "
          >Donate</a
        >
      </li>
    </ul>
  </div>
</template>

<script>
import { computed, ref } from "vue";

export default {
  setup() {
    const navItems = [
      { name: "home", href: "/#home" },
      { name: "about", href: "/#about" },
      { name: "programs", href: "/#programs" },
      { name: "contact", href: "/#contact" },
    ];

    let drawerState = ref(false);

    let drawerPos = computed(() => {
      return drawerState.value ? "-translate-x-0" : "-translate-x-full";
    });

    const toggleDrawer = () => {
      drawerState.value = !drawerState.value;
    };

    return { navItems, drawerState, drawerPos, toggleDrawer };
  },
};
</script>

<style scoped>
.active {
  font-weight: 600;
  --tw-text-opacity: 1;
  color: rgba(245, 158, 11, var(--tw-text-opacity));
  border-bottom: 2px solid rgba(245, 158, 11, var(--tw-text-opacity));
  border-start-end-radius: 50%;
  border-start-start-radius: 50%;
}

#nav {
  border-bottom: 1px solid rgb(216, 216, 216);
}
</style>