<template>
  <section class="w-full mt-10 md:mt-0" v-navItemTrigger id="contact">
    <div
      class="
        bg-gray-50
        px-10
        md:px-36
        py-10
        flex flex-col
        md:flex-row
        justify-around
      "
    >
      <div class="w-full md:w-1/3 text-left">
        <h4 class="font-bold">ACCSuD</h4>

        <br />
        <p class="text-sm text-justify text-gray-600 font-light">
          We believe that the website it is an important platform to share
          information, our success and many challenges in our work. We look
          forward for continued support from valued partners as we continue with
          our human rights work in Malawi.
        </p>
      </div>

      <div class="w-full md:w-auto text-left mt-7 md:mt-0">
        <h4 class="font-bold">Quick Links</h4>

        <br />
        <ul class="text-left">
          <li v-for="(navItem, i) in navItems" :key="i">
            <a
              :href="navItem.href"
              class="
                capitalize
                text-sm text-gray-600
                hover:text-gray-800
                transition-colors
                font-light
              "
            >
              {{ navItem.name }}
            </a>
          </li>
        </ul>
      </div>

      <div class="w-full md:w-auto text-left mt-7 md:mt-0">
        <h4 class="font-bold">Contact Us</h4>

        <br />
        <ul class="text-left text-sm text-gray-600 font-light">
          <li>+265 888 822 279 / 991 600 006</li>
          <li>info@accsud.org</li>
          <br />
          <li>Area 49 New Shire, Plot Number 49/265</li>
          <li>P.O Box 40620</li>
        </ul>
      </div>

      <div class="w-full md:w-auto text-left mt-7 md:mt-0">
        <h4 class="font-bold">Newsletter</h4>

        <form @submit.prevent="">
          <br />
          <input
            type="text"
            name=""
            id=""
            placeholder="Full Name"
            class="
              w-full
              md:w-auto
              border
              text-sm
              p-1
              outline-none
              focus:shadow-md
              transition-shadow
              font-light
            "
            required
          />

          <br />
          <input
            type="email"
            name=""
            id=""
            placeholder="Email"
            class="
              w-full
              md:w-auto
              border
              text-sm
              p-1
              outline-none
              focus:shadow-md
              transition-shadow
              font-light
              mt-4
            "
            required
          />

          <br />
          <button
            type="submit"
            class="
              mt-4
              bg-yellow-400
              hover:bg-yellow-500
              transition-colors
              font-medium
              w-full
              text-sm
              py-1
            "
          >
            Subscribe
          </button>
        </form>
      </div>
    </div>

    <div class="w-full bg-gray-200 px-10 md:px-36 py-2 flex flex-col md:flex-row justify-between">
      <div class="hidden md:block">
        <small class="text-gray-500 font-light"
          >&copy; ACCSuD | All rights reserved.</small
        >
      </div>

      <div class="w-3/5 md:w-1/6 m-auto md:m-0">
        <ul class="flex justify-between">
          <li>
            <a
              href="https://www.linkedin.com/"
              class="hover:text-yellow-600 transition-colors"
              ><i class="fab fa-linkedin" aria-hidden="true"></i
            ></a>
          </li>
          <li>
            <a
              href="https://www.facebook.com/"
              class="hover:text-yellow-600 transition-colors"
              ><i class="fab fa-facebook-square" aria-hidden="true"></i
            ></a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/"
              class="hover:text-yellow-600 transition-colors"
              ><i class="fab fa-instagram" aria-hidden="true"></i
            ></a>
          </li>
          <li>
            <a
              href="https://www.google.com/"
              class="hover:text-yellow-600 transition-colors"
              ><i class="fab fa-google" aria-hidden="true"></i
            ></a>
          </li>
        </ul>
      </div>

      <div class="visible md:hidden mt-4">
        <small class="text-gray-500 font-light"
          >&copy; ACCSuD | All rights reserved.</small
        >
      </div>
    </div>
  </section>
</template>

<script>
export default {
  setup() {
    const navItems = [
      { name: "home", href: "/#home" },
      { name: "about", href: "/#about" },
      { name: "programs", href: "/#programs" },
      { name: "contact", href: "/#contact" },
      //   { name: "donate", href: "/#donate"}
    ];

    return {
      navItems,
    };
  },
};
</script>

<style>
</style>